import PartySocket from "partysocket";

declare const PARTYKIT_HOST: string;

export class ChatClient {
  //
  conn: PartySocket;

  debugStateCallback: (state: any) => void = () => {};

  constructor(public opts = {} as any) {}

  connect() {
    this.conn = new PartySocket({
      host: PARTYKIT_HOST,
      room: "my-new-room",
      query: {
        userId: this.opts.userId ?? Math.random().toString(36).slice(2),
        userName: this.opts.userName ?? "Anonymous",
      },
    });

    this.conn.addEventListener("message", (event) => {
      this.onMessage(event.data);
    });

    return new Promise<void>((resolve) => {
      this.conn.addEventListener("open", () => {
        console.log("Connected to ", PARTYKIT_HOST);
        resolve();
      });
    });
  }

  disconnect() {
    this.conn.close();
  }

  onMessage(data: any) {
    const message = JSON.parse(data);

    if (message.type !== "debugState") {
      // console.log("Received message", message);
    }

    switch (message.type) {
      case "peerMessage":
        this.onPeerMessage(message.senderId, message.data);
        break;
      case "channelMessage":
        this.onChannelMessage(
          message.senderId,
          message.channelId,
          message.data
        );
        break;

      case "debugState":
        this.debugStateCallback(message.state);
        break;

      case "ack":
        break;

      default:
        console.error("Unknown message type", message);
    }
  }

  onPeerMessage(senderId: string, data: any) {
    console.log("Peer message from", senderId, data);
  }

  onChannelMessage(senderId: string, channelId: string, data: any) {
    console.log("Channel message from", senderId, "in", channelId, data);
  }

  private send(data: any) {
    this.conn.send(JSON.stringify(data));
  }

  request<T = any>(data: any) {
    return new Promise<T>((resolve) => {
      const replyId = Math.random().toString(36).slice(2);

      this.conn.send(JSON.stringify({ ...data, replyId }));

      const handler = (event) => {
        const message = JSON.parse(event.data);

        if (message.type === "ack" && message.replyId === replyId) {
          resolve(message.data);
          this.conn.removeEventListener("message", handler);
        }
      };
      this.conn.addEventListener("message", handler);
    });
  }

  joinChannel(channelId: string) {
    return this.request({ type: "joinChannel", channelId });
  }

  leaveChannel(channelId: string) {
    this.send({ type: "leaveChannel", channelId });
  }

  setChannelAttribute(channelId: string, key: string, value: any) {
    this.send({ type: "setChannelAttribute", channelId, key, value });
  }

  removeChannelAttribute(channelId: string, key: string) {
    this.send({ type: "removeChannelAttribute", channelId, key });
  }

  setUserAttribute(key: string, value: any) {
    this.send({ type: "setUserAttribute", key, value });
  }

  removeUserAttribute(key: string) {
    this.send({ type: "removeUserAttribute", key });
  }

  sendPeer(peerId: string, data: any) {
    this.send({ type: "sendPeer", peerId, data });
  }

  sendChannel(channelId: string, data: any) {
    this.send({ type: "sendChannel", channelId, data });
  }

  getUserAttributes(userId: string, keys: string[]) {
    return this.request({
      type: "getUserAttributes",
      userId,
      keys,
    });
  }

  getChannelAttributes(channelId: string, keys: string[]) {
    return this.request({
      type: "getChannelAttributes",
      channelId,
      keys,
    });
  }
}
