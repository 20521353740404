import { ChatClient } from "./ChatClient";

async function initChat() {
  //
  const client = new ChatClient();

  await client.connect();

  console.log("Connected!");

  globalThis.$chat = client;

  const stateEl = document.getElementById("state")!;

  client.debugStateCallback = (state) => {
    stateEl.innerText = JSON.stringify(state, null, 2);
  };
}

initChat();
